import React from "react"
import { Link } from "gatsby"
import Header from "../components/headers/header"
import Footer from "../components/footers/footer"
import SEO from "../components/seo"
import "./index.css"
import Avoidance from "@material-ui/icons/Devices"
import Impulsivity from "@material-ui/icons/Speed"
import Not from "@material-ui/icons/NotInterested"
import Reading from "@material-ui/icons/MenuBook"
import WastedTime from "@material-ui/icons/AccessTime"
import Knowledge from "@material-ui/icons/ImportContacts"
import Tools from "@material-ui/icons/LocalHospital"
import Support from "@material-ui/icons/People"
import mobile from "../images/blank_phone.png"
import background from "../images/background.png"
import ReactTextRotator from "react-text-rotator"

const content = [
  {
    text: "Social Media",
    className: "text-rotator",
    animation: "fade",
  },
  {
    text: "Your Phone",
    className: "text-rotator",
    animation: "fade",
  },
  {
    text: "Youtube Videos",
    className: "text-rotator",
    animation: "fade",
  },
  {
    text: "Binging Shows",
    className: "text-rotator",
    animation: "fade",
  },
  {
    text: "Video Games",
    className: "text-rotator",
    animation: "fade",
  },

  {
    text: "Reddit Posts",
    className: "text-rotator",
    animation: "fade",
  },
  {
    text: "Porn Videos",
    className: "text-rotator",
    animation: "fade",
  },
]
const StartHere = () => (
  <>
    <Header />
    <SEO
      title="Stop Technology Addiction"
      description="Life Beyond Screens provides you with everything you need to have a healthy relationship with technology, and life!"
    />
    <main style={{ marginTop: "4.4em" }}>
      <section id="intro">
        <div id="background-container">
          <h2>Live Life Beyond</h2>
          <ReactTextRotator content={content} time={3000} startDelay={0} />
          <Link
            className="button"
            style={{
              color: "#ffffff",
              borderColor: "#34495e",
            }}
            to="/vicedrop/"
          >
            Unlock How
          </Link>
          <img src={mobile} alt="phone" id="phone-intro" />

          <img src={background} alt="background" id="background" />
        </div>
        <div id="info-container">
          <h1>Get Your Life Back</h1>
          <p>
            Life Beyond Screens is a site dedicated to helping people stop
            self-destructive technology use. Whether you avoid addressing life
            problems or simply have a bad habit, we provide you with{" "}
            <Link to="/vicedrop/">everything you need</Link> to have a healthier
            relationship with technology, and life!
          </p>
        </div>
      </section>
      <section id="the-problem">
        <h2>The Problem</h2>
        <div id="problems">
          <div id="problem">
            <div id="problem-box">
              <Avoidance id="problem-icon" />
            </div>
            <h3 id="problem-title">Avoidance</h3>
            <div id="problem-description">
              Technology can provide the easiest way to avoid problems and
              neglect aspirations. Unlike drugs, technology provides unlimited
              supply, effortless availability, and is 'free'. The only cost,
              your true potential and greater suffering.
            </div>
          </div>
          <div id="problem">
            <div id="problem-box">
              <div id="icons">
                {" "}
                <Not id="problem-icon" /> <Reading id="problem-icon" />
              </div>
            </div>
            <h3 id="problem-title">Diminished Motivation</h3>
            <div id="problem-description">
              Overstimulation from technology hijacks the brains reward system,
              resulting in a numbed pleasure response to stimuli.
              <sup>
                [
                <a
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3312312/"
                  target="_blank"
                >
                  1
                </a>
                ]
              </sup>
              <sup>
                [
                <a
                  href="https://pubmed.ncbi.nlm.nih.gov/21499141/"
                  target="_blank"
                >
                  4
                </a>
                ]
              </sup>{" "}
              This causes a higher tolerance for receiving pleasure and
              diminishes motivation for doing effortful activities.
              <sup>
                [
                <a
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4600144/"
                  target="_blank"
                >
                  2
                </a>
                ]
              </sup>
              <sup>
                [
                <a
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6034956/"
                  target="_blank"
                >
                  8
                </a>
                ]
              </sup>
              <sup>
                [
                <a
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2948245/"
                  target="_blank"
                >
                  3
                </a>
                ]
              </sup>
            </div>
          </div>
          <div id="problem">
            <div id="problem-box">
              <Impulsivity id="problem-icon" />
            </div>
            <h3 id="problem-title">Increased Impulsivity</h3>
            <div id="problem-description">
              Technology addiction is closely associated with impulsivity.
              <sup>
                [
                <a
                  href="https://pubmed.ncbi.nlm.nih.gov/21499141/"
                  target="_blank"
                >
                  4
                </a>
                ]
              </sup>
              <sup>
                [
                <a
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4106030/"
                  target="_blank"
                >
                  5
                </a>
                ]
              </sup>{" "}
              The more severe the technology addiction is, the stronger positive
              correlation for having a reduced capacity to regulate impulsivity.
              <sup>
                [
                <a
                  href="https://www.researchgate.net/publication/225183971_Impulsivity_in_Internet_Addiction_A_Comparison_with_Pathological_Gambling"
                  target="_blank"
                >
                  6
                </a>
                ]
              </sup>
              <sup>
                [
                <a
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4146181/"
                  target="_blank"
                >
                  7
                </a>
                ]
              </sup>
            </div>
          </div>
          <div id="problem">
            <div id="problem-box">
              <WastedTime id="problem-icon" />
            </div>
            <h3 id="problem-title">Wasted Time</h3>
            <div id="problem-description">
              Self-destructive use of technology takes away our greatest asset,
              time. Mindlessly using technology is time that could be spent on
              more fulfilling things. Purposeful use of technology enables us to
              save our time rather than waste it.
            </div>
          </div>
        </div>
      </section>
      <section id="get-the-signs" style={{ backgroundColor: "#fff" }}>
        <h2 style={{ color: "#34495e" }}>Think you have a problem?</h2>
        <Link
          className="inverted-button"
          target="_blank"
          style={{
            color: "#283541",
            borderColor: "#283541",
          }}
          to="/signs/"
        >
          Get the signs
        </Link>
      </section>
      <section id="ensure-success">
        <h3> We help people resolve this issue by providing...</h3>
      </section>
      <section id="right-knowledge">
        <Knowledge id="section-icon" />
        <h2>The Right Knowledge</h2>
        <p>
          Our forever free science-based{" "}
          <a href="https://guide.lifebeyondscreens.com/" target="_blank">
            recovery guide
          </a>{" "}
          provides you with everything you need to do in order to successfully
          overcome self-destructive use of technology.
        </p>
      </section>
      <section id="right-tools">
        <Tools id="section-icon" />
        <h2>The Right Tools</h2>
        <p>
          If the recovery guide provides you with <i>what</i> you need to do,{" "}
          <Link to="/vicedrop/" target="_blank">
            ViceDrop{" "}
          </Link>
          is <i>how</i> you do it. It's a powerful recovery tool meant to be
          used in conjunction with the recovery guide, providing a convenient
          place to track progress, journal, and much more.
        </p>
      </section>
      <section id="right-support">
        <Support id="section-icon" />
        <h2>The Right Support</h2>
        <p>
          Our{" "}
          <a href="https://www.reddit.com/r/LifeBeyondScreens/" target="_blank">
            awesome community
          </a>{" "}
          is always there for you whenever you need a place discuss your
          progress, find an accountability partner, or simply just want to
          connect with others who are also going through recovery.
        </p>
      </section>
      <section id="start-journey">
        <h2>Ready for life beyond screens?</h2>
        <Link
          className="inverted-button"
          target="_blank"
          style={{
            color: "#283541",
            borderColor: "#283541",
          }}
          to="/vicedrop/"
        >
          start journey
        </Link>
      </section>
    </main>
    <Footer />
  </>
)

export default StartHere
